import React, { useEffect } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import QRCode from 'qrcode.react';
import './App.css';
import useWebSocket, { ReadyState } from "react-use-websocket";

const formatDate = (date) => {
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  return `${('0' + date.getDate().toString()).slice(-2)} ${month} -
  ${date.toLocaleTimeString('it-it').replace(/(.*)\D\d+/, '$1')}`;
};

function App() {
  // Set initial state
  const [lessons, setLessons] = React.useState([]);
  const [infos, setInfos] = React.useState('test');
  const [isLoading, setLoading] = React.useState(true);
  const [availableBooking, setAvailableBooking] = React.useState(false);
  const [endCurrentLesson, setEndCurrentLesson] = React.useState(null);
  const [created, setCreated] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [currentStamp, setCurrentStamp] = React.useState(formatDate(new Date()));
  // update current date every second
  useEffect(() => {
    setInterval(() => setCurrentStamp(formatDate(new Date())), 1000)
  }, []);

  const [shouldReconnect, setShouldReconnect] = React.useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams.has('class_id')) {
    console.error('no class_id found');
  }

  const classId = urlParams.get('class_id');

  const { sendMessage, lastMessage, readyState } = useWebSocket(`${process.env.REACT_APP_SOCKET_HOST}/${classId}`, {
    shouldReconnect: () => true,
    onClose: (e) => console.log('socket closed', e),
    onError: (e) => console.error(e),
    heartbeat: {
      message: () => {
        console.log('sending heartbeat');
        return 'ping';
      },
    },
    reconnectInterval: 250
  });

  useEffect(() => {
    if (lastMessage !== null) {
      // check pong message.
      if (lastMessage.data === 'pong') {
        console.log('getting pong', lastMessage.data);
        return;
      }
      // try decoding json message.
      try {
        const data = JSON.parse(lastMessage.data);
        if (data && data.action && data.action === 'update_nonce') {
          updateQR();
        }
      } catch (e) {
        console.error('invalid json message', e)
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (readyState) {
      console.log(`socket state: ${ReadyState[readyState]}`)
    }
  }, [readyState]);

  useEffect(() => {
    if (!shouldReconnect) {
      return
    }
    getLessons();
  }, [shouldReconnect]);

  if (!created) {
    setCreated(true);
  }

  const updateQR = async () => {
    console.log('UPDATING QR')
    const urlParams = new URLSearchParams(window.location.search);
    const classId = urlParams.get('class_id');
    if (!classId) {
      console.log('No class id in url...')
      return
    }
    try {
      const { data } = await axios.post('/api/v0/nonce', { class_id: classId })
      const response = await axios.get(`/api/v0/info?class_id=${classId}&app=true`);
      setErrorDialog(false);
      const currentLesson = response.data.result?.[0];
      console.log(response)
      const qrCodeUrl = {
        classroom: response.data.result.classroom,
        host: response.data.result.host,
        nonce: data.result.nonce,
        pb_address: response.data.result.pb_address,
        pb_port: response.data.result.pb_port,
        pb_schema: response.data.result.pb_schema,
      };
      const qrUrl = `${process.env.REACT_APP_POLI_BACKEND}/checkin/${currentLesson.id}/${qrCodeUrl.nonce}`;
      console.log(qrUrl)
      // const url = `${qrCodeUrl.pb_schema}://${qrCodeUrl.pb_address}:${qrCodeUrl.pb_port}/api/v0/checkin/${currentLesson.id}/${qrCodeUrl.nonce}`;
      setInfos(qrUrl);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorDialog(true);
    }
  };

  const getLessons = async () => {

    let interv = 60000;
    const currentDate = new Date().getTime();
    const urlParams = new URLSearchParams(window.location.search);
    let url = '';
    const classId = urlParams.get('class_id');
    if (urlParams.has('class_id')) {
      url = `/api/v0/info?class_id=${classId}&app=true`;
    } else {
      url = `/api/v0/info?app=true`;
    }

    try {
      const { data } = await axios.post('/api/v0/nonce', {
        class_id: classId
      })
      const response = await axios.get(url)
      setErrorDialog(false);
      const now = new Date();
      const lessonsMap = response.data.result.map((lesson) => {
        const [startHours, startMinutes] = lesson.start_time.split(':');
        const [stopHours, stopMinutes] = lesson.stop_time.split(':');
        lesson.start_time = `${('00' + (+startHours - now.getTimezoneOffset() / 60)).slice(-2)}:${('00' + (+startMinutes - now.getTimezoneOffset() % 60)).slice(-2)}`;
        lesson.stop_time = `${('00' + (+stopHours - now.getTimezoneOffset() / 60)).slice(-2)}:${('00' + (+stopMinutes - now.getTimezoneOffset() % 60)).slice(-2)}`;
        const startDateString = lesson.date.split('/').reverse().join('/');
        const startDate = new Date(`${startDateString} ${lesson.start_time}`);
        const availableDate = new Date(`${startDateString} ${lesson.start_time}`).setMinutes(startDate.getMinutes());
        const endDate = new Date(`${startDateString} ${lesson.stop_time}`);
        const endAvailableDate = new Date(`${startDateString} ${lesson.stop_time}`).setMinutes(
          endDate.getMinutes() - 15
        );
        setEndCurrentLesson(endAvailableDate);
        if (availableDate <= currentDate && currentDate < endDate) {
          if (currentDate < endAvailableDate) {
            if (endAvailableDate - currentDate < 60000) {
              interv = endAvailableDate - currentDate;
              if (interv < 0) interv = 60000;
            }
            setAvailableBooking(true);
          } else {
            if (endDate - currentDate < 60000) {
              interv = endDate - currentDate;
              if (interv < 0) interv = 60000;
            }
            setAvailableBooking(false);
          }
          return { ...lesson, current: true };
        } else {
          if (availableDate - currentDate < 60000) {
            interv = availableDate - currentDate;
            if (interv < 0) interv = 60000;
          }
          return { ...lesson, current: false };
        }
      });
      setLessons(lessonsMap);
      if (lessonsMap.length > 0 && lessonsMap[0].current) {
        const qrCodeUrl = {
          classroom: response.data.result.classroom,
          host: response.data.result.host,
          nonce: data.result.nonce,
          pb_address: response.data.result.pb_address,
          pb_port: response.data.result.pb_port,
          pb_schema: response.data.result.pb_schema,
        };
        // const url = `${qrCodeUrl.pb_schema}://${qrCodeUrl.pb_address}:${qrCodeUrl.pb_port}/api/v0/checkin/${lessonsMap[0].id}/${qrCodeUrl.nonce}`;
        const url = `${process.env.REACT_APP_POLI_BACKEND}/checkin/${lessonsMap[0].id}/${qrCodeUrl.nonce}`;
        setInfos(url);
        console.log('new url', url)
      }
      setLoading(false);
      setTimeout(() => {
        getLessons();
      }, interv);
    } catch (error) {
      console.log(error);
      if (currentDate < endCurrentLesson) {
        setAvailableBooking(true);
      } else {
        setAvailableBooking(false);
      }
      setLessons([]);
      setLoading(false);
      setErrorDialog(true);
      setTimeout(() => {
        getLessons();
      }, interv);
    }

  };

  return (
    <div className="main">
      <div className="header-wrapper">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-6">
              <h5 className="mt-2">{lessons.length ? lessons[0].classroom : ''}</h5>
            </div>
            <div className="col-6">
              {lessons.length ? (
                <img className="img-fluid ml-auto d-block main-logo" src={logo} alt="Logo Polimoda" />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="lessons-wrapper row align-items-center">
          {lessons.length === 0 ? (
            <div className="container">
              <img className="img-fluid mx-auto d-block main-logo" src={logo} alt="Logo Polimoda" />
              <h3 className="text-center mt-5">A new generation of creators</h3>
            </div>
          ) : (
            <div className="container">
              <div className="row align-items-center">
                {lessons[0].current ? (
                  <div className="col-12">
                    <div className="text-center mt-5">
                      <div className="card-body">
                        <h2 className="card-title">{lessons[0].title}</h2>
                        <h3 className="card-subtitle mb-2 mt-4 teacher-wrapper">{lessons[0].teacher}</h3>
                        <p className="card-text mb-5">
                          {lessons[0].start_time} - {lessons[0].stop_time}
                        </p>
                        {availableBooking && (
                          <div className="attendance-wrapper mt-5">
                            <h3>Attend</h3>
                            <div className="qr-wrapper text-center">{infos && <QRCode value={infos} />}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  !lessons[0].current && (
                    <div className="col-12">
                      <div className="text-center mt-5">
                        <div className="next-class mb-4">
                          <h3>Next class</h3>
                        </div>
                        <div className="card-body">
                          <h2 className="card-title mb-5">{lessons[0].title}</h2>
                          <h3 className="card-subtitle mb-2 teacher-wrapper">{lessons[0].teacher}</h3>
                          <p className="card-text mb-5">
                            {lessons[0].start_time} - {lessons[0].stop_time}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          <div className="datetime-wrapper w-100">
            <div className="container text-center">
              <p className="font-small">{currentStamp}</p>
            </div>
          </div>
        </div>
      )}
      {errorDialog && (
        <div className="error-dialog-wrapper">
          <div className="error-dialog">
            <div className="error-dialog-body text-center">
              <h2>Device out of service</h2>
              <p>Please contact the staff</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
